// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyABYzpXgI02779hCzsE6li4D3OQDdRgFEQ",
  authDomain: "chatapp-5802e.firebaseapp.com",
  projectId: "chatapp-5802e",
  storageBucket: "chatapp-5802e.appspot.com",
  messagingSenderId: "749497273179",
  appId: "1:749497273179:web:64d5bd6f98151a6f81287e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();